import { useFetcher } from 'react-router';
import React from 'react';

export function useResourceData<T>(path: string) {
  const fetcher = useFetcher<T>();

  React.useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data && path) {
      fetcher.load(path);
    }
  }, [fetcher, path]);

  return fetcher;
}

export function usePathChangingResourceData<T>(path: string) {
  const fetcher = useFetcher<T>();
  const [dataState, setDataState] = React.useState({ prevPath: '', finishedLoading: false });

  React.useEffect(() => {
    async function fetchData() {
      if (path !== dataState.prevPath) {
        setDataState({ prevPath: path, finishedLoading: false });
        await fetcher.load(path);
        setDataState({ prevPath: path, finishedLoading: true });
      }
    }
    void fetchData();
  }, [fetcher, path, dataState, setDataState]);

  return fetcher;
}
